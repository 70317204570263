exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/categoryPage.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-templates-gallery-list-page-tsx": () => import("./../../../src/templates/galleryListPage.tsx" /* webpackChunkName: "component---src-templates-gallery-list-page-tsx" */),
  "component---src-templates-gallery-tsx": () => import("./../../../src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */),
  "component---src-templates-portfolio-page-tsx": () => import("./../../../src/templates/portfolioPage.tsx" /* webpackChunkName: "component---src-templates-portfolio-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-simple-page-tsx": () => import("./../../../src/templates/simplePage.tsx" /* webpackChunkName: "component---src-templates-simple-page-tsx" */)
}

